import AuthSlice from 'store/slice/auth/authSlice'
import UserSlice from 'store/slice/user/userSlice'
import SavePatientSlice from "store/slice/patient/savePatientSlice";
import PatientSlice from "store/slice/patient/patientSlice";
import UpdatePatientSlice from "store/slice/patient/updatePatientSlice";
import DeletePatientSlice from "store/slice/patient/deletePatientSlice";
import DoctorSlice from "store/slice/doctor/doctorSlice";
import SaveDoctorSlice from "store/slice/doctor/saveDoctorSlice";
import UpdateDoctorSlice from "store/slice/doctor/updateDoctorSlice";
import DeleteDoctorSlice from "store/slice/doctor/deleteDoctorSlice";
import BookingSlice from "store/slice/booking/bookingSlice";
import TodayDoctorSlice from "store/slice/dashboard/todayDoctorSlice";
import OnlineAppointmentSlice from "store/slice/onlineAppointment/onlineAppointmentSlice";
import DashboardCountSlice from "store/slice/dashboard/dashboardCountSlice";
import TodayBookingSlice from "store/slice/dashboard/todayBookingSlice";
import PatientCheckSlice from "store/slice/PatientCheck/patientCheckSlice";
import DeletePatientCheckSlice from "store/slice/PatientCheck/deletePatientCheckSlice";
import SlotsByDoctorAndDateSlice from "store/slice/slot/slotsByDoctorAndDateSlice";
import DoctorsWithSlotCountSlice from "store/slice/doctor/doctorsWithSlotCountSlice";
import SaveBookingSlice from "store/slice/booking/saveBookingSlice";
import PatientByMobileNoSlice from "store/slice/patient/patientByMobileNoSlice";
import SlotSlice from "store/slice/slot/SlotSlice";
import BranchSlice from "store/slice/branch/branchSlice";
import GetBranchWithCharisSlice from "store/slice/branch/getBranchWithCharisSlice";
import SearchSlotSlice from "store/slice/slot/searchSlotSlice";
import SaveBranchWithChairsSlice from "store/slice/branch/saveBranchWithChairsSlice";
import UpdateBranchWithChairSlice from "store/slice/branch/updateBranchWithChairSlice";
import BranchWithChairCountSlice from "store/slice/branch/branchWithChairCountSlice";
import BranchByIdSlice from "store/slice/branch/branchByIdSlice";
import DeleteChairSlice from "store/slice/chair/deleteChairSlice";
import SaveSlotSlice from "store/slice/slot/saveSlotSlice";
import SaveUserSlice from "store/slice/user/saveUserSlice";
import DoctorsByBranchSlice from "store/slice/doctor/doctorsByBranchSlice";
import ProcedureSlice from "store/slice/procedure/procedureSlice";
import SaveProcedureSlice from "store/slice/procedure/saveProcedureSlice";
import UpdateProcedureSlice from "store/slice/procedure/updateProcedureSlice";
import DeleteProcedureSlice from "store/slice/procedure/deleteProcedureSlice";
import SaveExcelSheetSlice from "store/slice/procedure/saveExcelSheetSlice";
import TreatmentSlice from "store/slice/treatment/treatmentSlice";
import SaveTreatmentSlice from "store/slice/treatment/saveTreatmentSlice";
import UpdateTreatmentSlice from "store/slice/treatment/updateTreatmentSlice";
import DeleteTreatmentSlice from "store/slice/treatment/deleteTreatmentSlice";
import BookingDetailsSlice from "store/slice/bookingDetails/bookingDetailsSlice";
import SaveBookingDetailsSlice from "store/slice/bookingDetails/saveBookingDetailsSlice";
import DltBookingDetailsSlice from "store/slice/bookingDetails/dltBookingDetailsSlice";
import CheckinListSlice from "store/slice/booking/checkinListSlice";
import CheckoutListSlice from "store/slice/booking/checkoutListSlice";
import UpdateBookingStatusSlice from "store/slice/dashboard/updateBookingStatusSlice";
import ClinicalNoteByPatientSlice from "store/slice/clinicalNote/clinicalNoteByPatientSlice";
import SaveClinicalNoteSlice from "store/slice/clinicalNote/saveClinicalNoteSlice";
import DeleteClinicalNoteSlice from "store/slice/clinicalNote/deleteClinicalNoteSlice";
import PatientToothDetailsSlice from "store/slice/toothChart/patientToothDetailsSlice";
import UpdateRctSlice from "store/slice/toothChart/updateRctSlice";
import SaveExtraChargeSlice from "store/slice/extraCharge/saveExtraChargeSlice";
import DiscountSlice from "store/slice/discount/discountSlice";
import SaveDiscountSlice from "store/slice/discount/saveDiscountSlice";
import UpdateDiscountSlice from "store/slice/discount/updateDiscountSlice";
import DltDiscountSlice from "store/slice/discount/dltDiscountSlice";
import UpdatePrimaryStatusSlice from "store/slice/toothChart/updatePrimaryStatusSlice";
import ToothHistorySlice from "store/slice/toothChart/toothHistorySlice";
import UpdateDirectionSlice from "store/slice/toothChart/updateDirectionSlice";
import UpdateMissingSlice from "store/slice/toothChart/updateMissingSlice";
import UpdateFillingSlice from "store/slice/toothChart/updateFillingSlice";
import UpdateCrownSlice from "store/slice/toothChart/updateCrownSlice";
import UpdateBridgeSlice from "store/slice/toothChart/updateBridgeSlice";
import GetPaymentSlice from "store/slice/payment/getPaymentSlice";
import ActiveDiscountSlice from "store/slice/discount/activeDiscountSlice";
import AddPaymentSlice from "store/slice/payment/addPaymentSlice";
import PaymentSlice from "store/slice/payment/paymentSlice";
import SupplierSlice from "store/slice/supplier/supplierSlice";
import SaveSupplierSlice from "store/slice/supplier/saveSupplierSlice";
import UpdateSupplierSlice from "store/slice/supplier/updateSupplierSlice";
import DeleteSupplierSlice from "store/slice/supplier/deleteSupplierSlice";
import CategorySlice from "store/slice/category/categorySlice";
import SaveCategorySlice from "store/slice/category/saveCategorySlice";
import UpdateCategorySlice from "store/slice/category/updateCategorySlice";
import DeleteCategorySlice from "store/slice/category/deleteCategorySlice";
import ItemSlice from "store/slice/item/itemSlice";
import DeleteItemSlice from "store/slice/item/deleteItemSlice";
import SaveItemSlice from "store/slice/item/saveItemSlice";
import UpdateItemSlice from "store/slice/item/updateItemSlice";
import GrnSlice from "store/slice/grn/grnSlice";
import SaveGrnSlice from "store/slice/grn/saveGrnSlice";
import GetAllGrnSlice from "store/slice/grn/getAllGrnSlice";
import DailyUsageSlice from "store/slice/dailyUsage/dailyUsageSlice";
import SaveDailyUsageSlice from "store/slice/dailyUsage/saveDailyUsageSlice";
import ItemsByBranchSlice from "store/slice/item/itemsByBranchSlice";
import UpdateDailyUsageSlice from "store/slice/dailyUsage/updateDailyUsageSlice";
import UpdateDailyUsageStatusSlice from "store/slice/dailyUsage/updateDailyUsageStatusSlice";
import DeleteDailyUsageSlice from "store/slice/dailyUsage/deleteDailyUsageSlice";
import MainGrnSlice from "store/slice/mainGrn/mainGrnSlice";
import SaveMainGrnSlice from "store/slice/mainGrn/saveMainGrnSlice";
import SearchMainGrnSlice from "store/slice/mainGrn/searchMainGrnSlice";
import PurchaseOrderSlice from "store/slice/purchaseOrderMain/purchaseOrderSlice";
import PurchaseOrderItemsSlice from "store/slice/purchaseOrderMain/purchaseOrderItemsSlice";
import SavePurchaseOrderSlice from "store/slice/purchaseOrderMain/savePurchaseOrderSlice";
import UpdatePurchaseOrderSlice from "store/slice/purchaseOrderMain/updatePurchaseOrderSlice";
import DeletePurchaseOrderSlice from "store/slice/purchaseOrderMain/deletePurchaseOrderSlice";
import UpdatePurchaseOrderStatusSlice from "store/slice/purchaseOrderMain/updatePurchaseOrderStatusSlice";
import GetItemMainStockSlice from "store/slice/item/getItemMainStockSlice";
import SaveBranchPOSlice from "store/slice/purchaseOrderBranch/saveBranchPOSlice";
import DeleteBranchPOSlice from "store/slice/purchaseOrderBranch/deleteBranchPOSlice";
import UpdateBranchPOSlice from "store/slice/purchaseOrderBranch/updateBranchPOSlice";
import UpdateBranchPOStatusSlice from "store/slice/purchaseOrderBranch/updateBranchPOStatusSlice";
import BranchPOSlice from "store/slice/purchaseOrderBranch/branchPOSlice";
import BranchPOByIdSlice from "store/slice/purchaseOrderBranch/branchPOByIdSlice";
import GetAllItemsSlice from "store/slice/item/getAllItemsSlice";
import UpdateMainGrnSlice from "store/slice/mainGrn/updateMainGrnSlice";
import DltMainGrnSlice from "store/slice/mainGrn/dltMainGrnSlice";
import UpdateMainGrnStatusSlice from "store/slice/mainGrn/updateMainGrnStatusSlice";
import StockTransferSlice from "store/slice/stockTransfer/stockTransferSlice";
import UpdateStockTransferStatusSlice from "store/slice/stockTransfer/updateStockTransferStatusSlice";
import ReceiverBranchStockTransferItemsSlice from "store/slice/stockTransfer/receiverBranchStockTransferItemsSlice";
import SentBranchStockTransferItemsSlice from "store/slice/stockTransfer/sentBranchStockTransferItemsSlice";
import SaveStockTransferSlice from "store/slice/stockTransfer/saveStockTransferSlice";
import StockDashboardCount from "store/slice/stockDashboard/stockDashboardCount";
import DashboardCount from "store/slice/stockDashboard/dashboardCount";
import PoApproval from "store/slice/stockDashboard/poApproval";
import MainPurchaseOrder from "store/slice/stockDashboard/mainPurchaseOrder";
import DailyUsagePending from "store/slice/stockDashboard/dailyUsagePending";
import LocationWiseIncomeSlice from 'store/slice/report/locationWiseIncomeSlice';
import DoctorWiseSalesSlice from "store/slice/report/doctorWiseSalesSlice";
import BookingHistorySlice from 'store/slice/report/bookingHistorySlice';
import ProcedureWiseSalesSlice from 'store/slice/report/procedureWiseSalesSlice';

const rootReducer = {
    AuthSlice,
    UserSlice,
    SavePatientSlice,
    PatientSlice,
    UpdatePatientSlice,
    DeletePatientSlice,
    DoctorSlice,
    SaveDoctorSlice,
    UpdateDoctorSlice,
    DeleteDoctorSlice,
    BookingSlice,
    TodayDoctorSlice,
    OnlineAppointmentSlice,
    DashboardCountSlice,
    TodayBookingSlice,
    PatientCheckSlice,
    DeletePatientCheckSlice,
    SlotsByDoctorAndDateSlice,
    DoctorsWithSlotCountSlice,
    SaveBookingSlice,
    PatientByMobileNoSlice,
    SlotSlice,
    BranchSlice,
    GetBranchWithCharisSlice,
    SearchSlotSlice,
    SaveBranchWithChairsSlice,
    UpdateBranchWithChairSlice,
    BranchWithChairCountSlice,
    BranchByIdSlice,
    DeleteChairSlice,
    SaveUserSlice,
    SaveSlotSlice,
    DoctorsByBranchSlice,
    ProcedureSlice,
    SaveProcedureSlice,
    UpdateProcedureSlice,
    DeleteProcedureSlice,
    SaveExcelSheetSlice,
    TreatmentSlice,
    SaveTreatmentSlice,
    UpdateTreatmentSlice,
    BookingDetailsSlice,
    SaveBookingDetailsSlice,
    DltBookingDetailsSlice,
    CheckinListSlice,
    CheckoutListSlice,
    DeleteTreatmentSlice,
    UpdateBookingStatusSlice,
    ClinicalNoteByPatientSlice,
    SaveClinicalNoteSlice,
    DeleteClinicalNoteSlice,
    PatientToothDetailsSlice,
    UpdateRctSlice,
    UpdatePrimaryStatusSlice,
    ToothHistorySlice,
    UpdateDirectionSlice,
    UpdateMissingSlice,
    UpdateFillingSlice,
    SaveExtraChargeSlice,
    DiscountSlice,
    SaveDiscountSlice,
    UpdateDiscountSlice,
    DltDiscountSlice,
    UpdateBridgeSlice,
    UpdateCrownSlice,
    GetPaymentSlice,
    ActiveDiscountSlice,
    AddPaymentSlice,
    PaymentSlice,
    CategorySlice,
    SaveCategorySlice,
    UpdateCategorySlice,
    DeleteCategorySlice,
    ItemSlice,
    DeleteItemSlice,
    UpdateItemSlice,
    SaveItemSlice,
    SupplierSlice,
    SaveSupplierSlice,
    UpdateSupplierSlice,
    DeleteSupplierSlice,
    DailyUsageSlice,
    SaveDailyUsageSlice,
    ItemsByBranchSlice,
    UpdateDailyUsageSlice,
    UpdateDailyUsageStatusSlice,
    DeleteDailyUsageSlice,
    MainGrnSlice,
    SaveMainGrnSlice,
    SearchMainGrnSlice,
    PurchaseOrderSlice,
    PurchaseOrderItemsSlice,
    SavePurchaseOrderSlice,
    DeletePurchaseOrderSlice,
    UpdatePurchaseOrderSlice,
    UpdatePurchaseOrderStatusSlice,
    SaveBranchPOSlice,
    DeleteBranchPOSlice,
    UpdateBranchPOSlice,
    UpdateBranchPOStatusSlice,
    BranchPOSlice,
    BranchPOByIdSlice,
    GetAllItemsSlice,
    UpdateMainGrnSlice,
    DltMainGrnSlice,
    UpdateMainGrnStatusSlice,
    GrnSlice,
    SaveGrnSlice,
    GetAllGrnSlice,
    GetItemMainStockSlice,
    StockTransferSlice,
    UpdateStockTransferStatusSlice,
    ReceiverBranchStockTransferItemsSlice,
    SentBranchStockTransferItemsSlice,
    SaveStockTransferSlice,
    StockDashboardCount,
    DashboardCount,
    PoApproval,
    MainPurchaseOrder,
    DailyUsagePending,
    LocationWiseIncomeSlice,
    DoctorWiseSalesSlice,
    BookingHistorySlice,
    ProcedureWiseSalesSlice
}

export default rootReducer;
