import { createAsyncThunk } from "@reduxjs/toolkit";
import instance from "services/AxiosOrders";

export const getLocationWiseIncome = createAsyncThunk(
    'get-location-wise-income',
    async (searchDetails, { rejectWithValue }) => {
        try {
            const { data } = await instance.get(`/report/location_wise_income`, {
                params: {
                    startDate: `${searchDetails.startDate} 00:00:00`,
                    endDate: `${searchDetails.endDate} 23:59:59`
                }
            });
            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const getDoctorWiseSales = createAsyncThunk(
    'get-doctor-wise-sales',
    async (searchDetails, { rejectWithValue }) => {
        try {
            const { data } = await instance.get(`/report/doctor_wise_sales`, {
                params: {
                    startDate: `${searchDetails.startDate} 00:00:00`,
                    endDate: `${searchDetails.endDate} 23:59:59`,
                    branchId: searchDetails.branchDetails.branchId
                }
            });
            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const getBookingHistory = createAsyncThunk(
    'get-booking-history',
    async (searchDetails, { rejectWithValue }) => {
        try {
            const { data } = await instance.get(`/report/booking_history`, {
                params: {
                    startDate: searchDetails.startDate,
                    endDate: searchDetails.endDate,
                    branchId: searchDetails.branchId,
                    doctorId: searchDetails.doctorId,
                    shift: searchDetails.shift
                }
            });
            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const getProcedureWiseSales= createAsyncThunk(
    'get-procedure-wise-sales',
    async (searchDetails, { rejectWithValue }) => {
        try {
            const { data } = await instance.get(`/report/procedure_wise_sales`, {
                params: {
                    startDate: searchDetails.startDate,
                    endDate: searchDetails.endDate,
                    branchId: searchDetails.branchId,
                    doctorId: searchDetails.doctorId,
                    shift: searchDetails.shift
                }
            });
            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

